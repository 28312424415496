import React from "react"
import { Spring } from "react-spring/renderprops.cjs"

export default WrappedComponent => {
  return function wrappedRender(props) {
    return (
      <Spring
        config={{ delay: 50 }}
        from={{
          transform: "translateY(-30px)",
        }}
        to={{
          transform: "translateY(0px)",
        }}
      >
        {({ transform }) => (
          <div style={{ transform }}>
            <WrappedComponent {...props} />
          </div>
        )}
      </Spring>
    )
  }
}
