import React from "react"
import ReactMarkdown from "react-markdown"

import { Container, Title, Content } from "./styled"
import { Wrapper } from "../../../styled"

const Links = props => {
  return (
    <Wrapper>
      <Container>
        <Title>{props.title}</Title>
        <Content>
          <div>
            <ReactMarkdown
              source={props.oneColumn.oneColumn}
              renderers={{
                link: props => (
                  <a
                    href={props.href}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    {props.children}
                  </a>
                ),
              }}
              escapeHtml={false}
            />
          </div>

          <div>
            <ReactMarkdown
              source={props.twoColumns.twoColumns}
              renderers={{
                link: props => (
                  <a
                    href={props.href}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    {props.children}
                  </a>
                ),
              }}
              escapeHtml={false}
            />
          </div>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Links
