import styled from "styled-components"

export const Container = styled.div`
  background-color: ${props => props.theme.colors.grey};
  text-align: center;
  padding: 55px 0 55px 0;

  ${props => props.theme.media.mediumPlus`
    padding:35px 15px;
    text-align:left;
  `}

  h1 {
    font-size: 30px;
    margin-bottom: 55px;
  }
`

export const SubContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;

  ${props => props.theme.media.mediumPlus`
    flex-direction:row;
    flex-wrap: nowrap;
    max-width:unset;
    width:100%;
    overflow:scroll;
  `}
`
