import styled from "styled-components"

export const Container = styled.div`
  text-align: center;
  padding: 35px 0;
  border-top: 1px solid ${props => props.theme.colors.grey};
  border-bottom: 1px solid ${props => props.theme.colors.grey};

  ${props => props.theme.media.mediumPlus`
    text-align:left;
  `}
`

export const Title = styled.h1`
  color: ${props => props.theme.colors.blue};
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 80px;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.theme.media.mediumPlus`
    flex-direction:column;
  `}

  & > div {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  strong {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    a {
      display: grid;
      margin-top: 30px;
      transition: all 400ms;

      ${props => props.theme.media.mediumPlus`
        margin: 30px 0;
      `}

      &:hover {
        transition: all 400ms;
        color: ${props => props.theme.colors.lightBlue};
        font-weight: bold;
      }
    }
  }
`
