import React from "react"
import { Container, SubContainer } from "./styled"

import Guide from "../guides"
const ListGuides = props => {
  console.log(props.categories)

  return (
    <Container id={props.id}>
      <h1>{props.title}</h1>

      <SubContainer>
        {props.guides.map((guide, i) => {
          return <Guide key={i} guide={guide} />
        })}
      </SubContainer>
    </Container>
  )
}
export default ListGuides
