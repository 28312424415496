import styled from "styled-components"
import { StandardBtn } from "../../../styled"

const currentColor = props =>
  props.categories === "Immobilier"
    ? props.theme.colors.green
    : props.theme.colors.yellow

export const Btn = styled(StandardBtn)`
  color: ${currentColor};
  border: 1px solid ${currentColor};
  font-size: 26px;
  padding: 20px 30px;
  font-family: "Muli";
`

export const Container = styled.div`
  text-align: center;
  padding: 55px 0;

  ${props => props.theme.media.mediumPlus`
    padding:35px 0;
  `}
`
