import styled from "styled-components"

const currentColor = props =>
  props.categories === "Immobilier"
    ? props.theme.colors.green
    : props.theme.colors.yellow

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  ${props => props.theme.media.mediumPlus`
    width:100%;
  `}

  h1 {
    font-family: "Muli";
    font-weight: bold;
    font-size: 30px;
    color: ${currentColor};
    text-transform: uppercase;
    text-align: ${props =>
      props.position === "ImageDown" ? "center" : "left"};
  }

  h2 {
    font-family: "Muli";
    font-weight: normal;
    font-size: 18px;
    color: ${props => props.theme.colors.blue};
    text-align: ${props =>
      props.position === "ImageDown" ? "center" : "left"};
    margin-bottom: 40px;
  }

  h3 {
    display: none;
  }

  p {
    color: ${props => props.theme.colors.black};
    margin-bottom: 10px;
    text-align: ${props =>
      props.position === "ImageDown" ? "center" : "left"};
  }

  ul {
    list-style: none;
  }

  li::before {
    content: "•";
    margin-right: 10px;
    position: relative;
    top: 2px;
    font-size: 30px;
    color: ${currentColor};
  }

  a {
    border-bottom: 1px solid ${currentColor};
    text-decoration: none;
    line-height: 0.7;
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 4.5px 0;
    overflow: hidden;
    vertical-align: bottom;
    transition: color 0.3s ease-out;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      transform: translateY(calc(100%));
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        60deg,
        ${currentColor} 0%,
        ${currentColor} 100%
      );
      transition: transform 0.25s ease-out;
    }

    &:hover {
      color: #fff;
      font-weight: bold;
      transition: all 0.25s ease-out;
    }

    &:hover::before {
      transform: translateY(0);
      transition: transform 0.25s ease-out;
    }
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${props => props.theme.media.mediumPlus`
    flex-direction:column;
  `}

  div {
    width: ${props => (props.hasTwoColumns ? "45%" : "100%")};

    ${props => props.theme.media.mediumPlus`
      width:100%;
    `}
  }
`
