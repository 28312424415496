import styled from "styled-components"

const currentColor = props =>
  props.categories === "Immobilier"
    ? props.theme.colors.green
    : props.theme.colors.yellow

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props =>
    props.position === "ImageRight" ||
    props.position === "ImageRight_2-3" ||
    props.position === "ImageRight_1-3"
      ? "row"
      : props.position === "ImageDown"
      ? "column"
      : "row-reverse"};

  ${props => props.theme.media.mediumPlus`
    flex-direction: column;
  `}
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.mediumPlus`
    padding: 15px 0;
  `}

  h1 {
    font-family: "Muli";
    font-weight: bold;
    font-size: 30px;
    color: ${currentColor};
    text-transform: uppercase;
    text-align: ${props =>
      props.position === "ImageDown" ? "center" : "left"};

    ${props => props.theme.media.mediumPlus`
      text-align:left;
    `}
  }

  h2 {
    font-family: "Muli";
    font-weight: normal;
    font-size: 18px;
    color: ${props => props.theme.colors.blue};
    text-align: ${props =>
      props.position === "ImageDown" ? "center" : "left"};
    margin-bottom: 60px;

    ${props => props.theme.media.mediumPlus`
      text-align:left;
      margin-bottom: 30px;
    `}
  }
  h3 {
    display: none;
  }

  p {
    color: ${props => props.theme.colors.black};
    margin-bottom: ${props => (props.position === "ImageDown" ? "30px" : "0")};
    text-align: ${props =>
      props.position === "ImageDown" ? "center" : "left"};

    ${props => props.theme.media.mediumPlus`
      text-align:left;
    `}
  }

  ul {
    list-style: none;

    ${props => props.theme.media.mediumPlus`
      margin: 0;
   `}
  }

  li::before {
    content: "•";
    margin-right: 10px;
    position: relative;
    top: 2px;
    font-size: 30px;
    color: ${currentColor};
  }

  a {
    border-bottom: 1px solid ${currentColor};
    text-decoration: none;
    line-height: 0.7;
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 4.5px 0;
    overflow: hidden;
    vertical-align: bottom;
    transition: color 0.3s ease-out;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      transform: translateY(calc(100%));
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        60deg,
        ${currentColor} 0%,
        ${currentColor} 100%
      );
      transition: transform 0.25s ease-out;
    }

    &:hover {
      color: #fff;
      font-weight: bold;
      transition: all 0.25s ease-out;
    }

    &:hover::before {
      transform: translateY(0);
      transition: transform 0.25s ease-out;
    }
  }

  .gatsby-image-wrapper {
    width: ${props =>
      props.position === "ImageDown"
        ? "100%"
        : props.position === "ImageRight_2-3" ||
          props.position === "ImageLeft_2-3"
        ? "70%"
        : props.position === "ImageRight_1-3" ||
          props.position === "ImageLeft_1-3"
        ? "30%"
        : "50%"};

    ${props => props.theme.media.mediumPlus`
      width:100%;
      margin-top: 35px;
    `}
  }
`
export const Content = styled.div`
  width: ${props =>
    props.position === "ImageDown"
      ? "100%"
      : props.position === "ImageRight_1-3" ||
        props.position === "ImageLeft_1-3"
      ? "70%"
      : "50%"};
  padding-right: ${props =>
    props.position === "ImageRight" ||
    props.position === "ImageRight_2-3" ||
    props.position === "ImageRight_1-3"
      ? "90px"
      : "0"};
  padding-left: ${props =>
    props.position === "ImageRight" ||
    props.position === "ImageRight_2-3" ||
    props.position === "ImageRight_1-3"
      ? "0"
      : "90px"};

  ${props => props.theme.media.mediumPlus`
    width:100%;
    padding:0;
  `}
`
