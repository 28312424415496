import styled from "styled-components"

const currentColor = props =>
  props.categories === "Immobilier"
    ? props.theme.colors.green
    : props.theme.colors.yellow

export const Container = styled.ul`
  background-color: ${props => props.theme.colors.grey};
  width: 60%;
  padding: 30px 150px;
  margin: 0 auto 55px auto;
  position: relative;

  ${props => props.theme.media.mediumPlus`
    width:100%;
    padding:30px;
  `}

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 50px;
    text-transform: uppercase;
  }

  span {
    background-color: ${currentColor};
    padding: 5px;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  a {
    align-items: center;
    display: flex;
    flex: 1 0 50%;
    margin-bottom: 15px;
    span {
      margin-right: 10px;
    }
  }
  ul {
    flex-wrap: wrap;
  }
  li {
    list-style: none;
    margin: 0;
  }
`

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${props => props.theme.media.mediumPlus`
    flex-wrap:no-wrap;
    flex-direction:column;
  `}
`
