import React from "react"

import { Btn, Container } from "./styled"

const Cta = props => {
  return (
    <Container>
      <a href={props.link}>
        <Btn categories={props.categories}>{props.label}</Btn>
      </a>
    </Container>
  )
}

export default Cta
